<template>
  <div class="view-buyer-live">
    <div class="live-auction-house-header">
      <div class="live-auction-house-header-1">
        <img src="../../../assets/images/logo.png" alt="" />
      </div>
      <div class="live-auction-house-header-2">
        <p class="title-1">{{ auctionTitle }}</p>
        <p class="title-2">{{ auctionHouseTitle }}</p>
      </div>
      <div class="live-auction-house-header-3">
        <p class="welcome-1">欢迎</p>
        <!-- <p class="welcome-2">
          <a href="javascript:;">拍卖条款</a>
          <span class="line-pad">|</span>
          <a href="javascript:;">服务规则</a>
        </p> -->
      </div>
    </div>
    <div class="buyer-live-middle">
      <div class="buyer-live-middle-content-1">
        <div class="buyer-live-middle-content-1-top">
          <div class="buyer-live-middle-content-1-top-left">
            <div class="col-md-3">
              <div class="thumb-container">
                <div class="thumb">
                  <a href="javascript:;">
                    <!-- <viewer :images="imgList"> -->
                    <img :src="auctionItemOne.img+imgSize(518)" alt="" />
                    <!-- </viewer> -->
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="buyer-live-middle-content-1-top-right">
            <p class="p-1">
              Lot {{ auctionItemOne.lot }}: {{ auctionItemOne.title }}
            </p>
            <p class="p-2">拍品估价</p>
            <p class="p-3">
              {{ auctionItemOne.assess }}{{ auctionItemOne.currency }}
            </p>
            <p class="p-4">商品描述</p>
            <p class="p-5" v-html="auctionItemOne.item_overview">
              <!-- {{}} -->
            </p>
          </div>
        </div>
        <div class="buyer-live-middle-content-1-right">
          <div class="buyer-live-middle-content-1-right-top">
            <i class="iconfont icon-xiangzuo text-font" @click="leftScroll"></i>
            <div class=" img-w" :class="{ 'img-w-bor': item.id === nextItem }" v-for="(item, index) in newArry"
              :key="index">
              <div class="col-md-3">
                <div class="thumb-container">
                  <div class="thumb">
                    <a href="javascript:;">
                      <!-- <viewer :images="imgList"> -->
                      <img :src="item.img+imgSize(150)" alt="" />
                      <!-- </viewer> -->
                    </a>
                  </div>
                </div>
              </div>
              <div class="h-block" v-if="item.status === '成交'"></div>
              <div class="img-w-content">
                <p>
                  <span>Lot{{ item.lot }}-</span>
                  <span v-if="item.id !== nextItem">{{ item.status }}</span>
                  <span v-else>直播中</span>
                </p>
                <p v-if="item.status === '成交'">{{ item.price }}</p>
              </div>
            </div>
            <i class="iconfont icon-xiangyou text-font" @click="rightScroll"></i>
          </div>
          <div class="buyer-live-middle-content-1-right-bottom">
            <a href="javascript:;" @click="goBack()">返回当前拍品</a>
            <p class="first-c ">{{ value1 }}</p>
            <div class="silder-right">
              <el-slider v-model="value1" :max="value2" :step="6" @change="Onchange($event)">
              </el-slider>
            </div>
            <p class="first-c-1">{{ value2 }}</p>
          </div>
        </div>
      </div>
      <div class="buyer-live-middle-content-2">
        <div class="buyer-live-middle-content-2-top">
          <div class="buyer-live-middle-content-2-top-video" id="video" v-show="audienceJoinShow">
            <i class="iconfont icon-shanchu" id="leave" @click.stop="audienceJoinOff()"></i>
          </div>
          <a href="javascript:;" class="look-video" @click="lookVideo()" v-if="audienceJoinShowBtn">观看视频直播</a>
          <div class="message-content-more">
            <div class="message-content-more-scroll" :class="
                item.message_type === 1
                  ? 'message-content-more-scroll-toggle'
                  : item.message_type === 3
                  ? 'message-content-more-scroll-toggle-1'
                  : item.message_type === 4
                  ? 'message-content-more-scroll-toggle-2'
                  : ''
              " v-for="(item, index) in messageList" :key="index">
              {{ item.content }}
            </div>
          </div>
        </div>
        <div class="buyer-live-middle-content-2-bottom">
          <div class="price-s-b">
            <span>保证金{{ earnestMoney }}</span>
            <div class="price-s-b-right">
              <span v-if="moneyShow">剩余额度:{{ userAvailableBalanceNew}}元</span>
              <span v-else @click="loginOpen()" style="cursor: pointer;">剩余额度:请登录</span>
              <router-link v-if="moneyShow&&accountStatus==0" target="_blank" :to="{ path: '/personalcenter', query: { fige: 1 } }">
                立即充值
              </router-link>
            </div>
          </div>
          <div class="price-s-b-btn">
            <a href="javascript:" class="false-price" v-if="btnPriceShow">
              <span>立即出价:</span>
              <span>{{ money }}{{ currency }}</span>
            </a>
            <a href="javascript:" @click="makeImmediateOffer()" v-else>
              <span>立即出价:</span>
              <span>{{ money }}{{ currency }}</span>
            </a>
            <span>约合人民币{{ exchangeCount }}元</span>
          </div>
        </div>
      </div>
    </div>
    <login ref="login" />
  </div>
</template>
<script>
  import {
    getAccountDetails,
    getAgoraToken,
    userInfo,
    getLiveAuctionItemList,
    accountStatus
  } from '../../.././api/index'
  import startBasicLiveStreaming from '../../../utils/basicLiveStreaming'
  import utils from '@/utils/utils'
  import login from '../../../components/login'
  import AgoraRTC from 'agora-rtc-sdk-ng'
  export default {
    name: 'buyerLive',
    components: {
      login
    },
    data() {
      return {
        value1: 1,
        value2: 0,
        auctionTitle: '',
        earnestMoney: '',
        initialPrice: null,
        exchangeRate: 0,
        exchangeCount: 0,
        messageList: [],
        data: '',
        userAvailableBalance: 0,
        auctionItemList: [],
        auctionItemOne: {},
        pageSizeNum: 1,
        currentPage: 1,
        pageSize: 6,
        newArry: [],
        moneyRmbData: 0,
        money: 0,
        getAccountDetailsData: {
          type: '全部',
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 10
        },
        getAccountDetailsDataList: {},
        btnPriceShow: true,
        lockReconnect: false, // 是否真正建立连接
        timeout: 10 * 1000, // 30秒一次心跳
        timeoutObj: null, // 心跳心跳倒计时
        serverTimeoutObj: null, // 心跳倒计时
        timeoutnum: null, // 断开 重连倒计时
        agoraToken: '',
        audienceJoinShow: false,
        audienceJoinShowBtn: true,
        nextItem: '',
        cardStatus: null,
        moneyShow: false,
        auctionHouseTitle: '',
        currency: null,
        accountStatus:0
      }
    },
    computed: {
      userAvailableBalanceNew() { // 剩余额度乘以保证金
        let earnestMoney = []
        if (this.earnestMoney) {
          earnestMoney = this.earnestMoney.split(':')
        }
        const earnestMoneyNum = Number(earnestMoney[1]) / Number(earnestMoney[0])
        const AvailableBalance = this.formatDel(this.userAvailableBalance)
        return this.format((AvailableBalance * earnestMoneyNum).toFixed(2))
      }
    },
    created() {
      this.auctionTitle = this.$route.query.title
      this.initWebSocket()
      this.getAgoraToken()
      if (localStorage.getItem('accessToken')) {
        this.userInfo()
        this.getAccountDetailsList()
      }
      if (localStorage.getItem('accessToken')) {
        this.moneyShow = true
      } else {
        this.moneyShow = false
      }
      this.auctionHouseTitle = this.$route.query.auctionHouseTitle
    },
    methods: {
      async getAccountDetailsList() {
        const res = await accountStatus({
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
        })
        if (res.code === 0) {
          this.accountStatus = res.data.account_status
        } else {
          this.$message.error(res.msg)
        }
      },
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      format(num) { // 千分位
        return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
      },
      formatDel(num) { // 去除千分号
        const newval = num.toString().replace(/,/gi, '')
        return Number(newval)
      },
      getcurrentPage() {
        // 定位到当前页的数据
        this.auctionItemList = JSON.parse(localStorage.getItem('auctionItemList'))
        const index =
          this.auctionItemList?.findIndex(item => item.id === this.nextItem) + 1 // 第几条数据在直播
        const remainder = index % 6 // 分页后余数
        const elsePage = remainder ? 1 : 0 // 有余数需再加一页
        this.currentPage = parseInt(index / 6) + elsePage // 当前页
        this.value1 = index // 当前正在直播的第几条数据
        this.getResultsData()
      },
      // 拖拽滚动图片
      Onchange(event) {
        // console.log(event);
        // const eventCount = Number(event) - 1
        // const page = eventCount / 6
        // this.pageSizeNum = page
        // this.currentPage = page
        const index = Number(event)
        const remainder = index % 6 // 分页后余数
        const elsePage = remainder ? 1 : 0 // 有余数需再加一页
        this.currentPage = parseInt(index / 6) + elsePage // 当前页
        this.getResultsData()
      },
      // 点击左边切换图片
      leftScroll() {
        if (this.currentPage === 1) return
        this.currentPage--
        this.getResultsData()
      },
      // 点击右边切换图片
      rightScroll() {
        this.currentPage++
        this.getResultsData()
      },
      // 分页
      getResultsData() {
        const list = JSON.parse(localStorage.getItem('auctionItemList'))
        this.newArry = list.filter(
          (item, index) =>
          index < this.currentPage * this.pageSize &&
          index >= this.pageSize * (this.currentPage - 1)
        )
        if (this.newArry.length === 0) {
          this.currentPage = 1
          // this.pageSizeNum = 1
          this.getResultsData()
        }
      },
      // 获取声网Token
      async getAgoraToken() {
        const res = await getAgoraToken({
          uid: 0,
          channelName: this.$route.query.auctionId.toString(),
          role: 0,
          expireTime: 43200
        })
        if (res.code === 0) {
          this.agoraToken = res.data.token
        } else {
          this.$message.error(res.msg)
        }
      },
      async audienceJoinOff() {
        this.audienceJoinShow = false
        this.audienceJoinShowBtn = true

        const client = AgoraRTC.createClient({
          mode: 'live',
          codec: 'vp8'
        })

        client.remoteUsers.forEach(user => {
          // Destroy the dynamically created DIV containers.
          const playerContainer = document.getElementById(user.uid)

          playerContainer && playerContainer.remove()
        })
        await client.leave()
        const video = document.getElementById('video')
        const children = video.getElementsByTagName('div')
        children.forEach(item => {
          item.remove()
        })
      },
      lookVideo() {
        this.audienceJoinShow = true
        this.audienceJoinShowBtn = false
        const videoDiv = document.getElementById('video')
        const children = videoDiv.getElementsByTagName('div')
        if (children.length !== 2) {
          startBasicLiveStreaming(
            'bac2562465b34ba0b4865724cd957bac',
            this.$route.query.auctionId,
            this.agoraToken,
            0
          )
        }
      },
      async leaveVideo() {
        const rtc = {
          // For the local audio and video tracks.
          localAudioTrack: null,
          localVideoTrack: null,
          client: null
        }
        // // Close all the local tracks.
        rtc.localAudioTrack.close()
        rtc.localVideoTrack.close()
        // // Traverse all remote users.
        rtc.client.remoteUsers.forEach(user => {
          // Destroy the dynamically created DIV containers.
          const playerContainer = document.getElementById(user.uid)
          playerContainer && playerContainer.remove()
        })
        // Leave the channel.
        await rtc.client.leave()
        // rtc.client = document.getElementById('leave')
        // // rtc.localAudioTrack.close()
        // // rtc.localVideoTrack.close()
        // rtc.client.remoteUsers.forEach(user => {
        //   console.log(user)
        //   // Destroy the dynamically created DIV containers.
        //   const playerContainer = document.getElementById(user.uid)
        //   playerContainer && playerContainer.remove()
        // })

        // // Leave the channel.
        // await rtc.client.leave()
      },
      initWebSocket() {
        // 初始化weoSocket
        //const url = 'wss://bid.test.easyebid.com/run'
        const url = 'wss://bid.easyebid.com/run'
        this.webSock = new WebSocket(url)
        this.webSock.onmessage = this.webSocketOnmessage
        this.webSock.onopen = this.webSocketOnopen
        this.webSock.onerror = this.webSocketOnerror
        this.webSock.onclose = this.webSocketClose
      },
      webSocketOnopen() {
        // 连接建立之后执行send方法发送数据
        const actions =
          'login' +
          ',' +
          this.$route.query.liveId +
          ',' +
          localStorage.getItem('accessId') +
          ',' +
          this.$route.query.auctionId
        const encode = utils.baseEncode(actions)
        this.webSocketSend(encode)
        // 开启心跳
        // this.reset()
      },
      webSocketOnerror() {
        // 连接建立失败重连
        // this.reconnect()
      },
      async getLiveAuctionItemList() {
        const res = await getLiveAuctionItemList({
          auctionId: Number(this.$route.query.auctionId)
        })
        if (res.code === 0) {
          localStorage.setItem(
            'auctionItemList',
            JSON.stringify(res.data.auction_item_list)
          )
          this.auctionItemListFor(
            JSON.parse(localStorage.getItem('auctionItemList'))
          )
          this.auctionItemList = JSON.parse(
            localStorage.getItem('auctionItemList')
          )
          localStorage.setItem('countLength', res.data.auction_item_list.length)

          this.value2 = res.data.auction_item_list.length
          this.getcurrentPage()
          // this.getResultsData()
        } else {
          this.$message.error(res.msg)
        }
      },
      // minNnm () {
      //   this.auctionItemList = JSON.parse(localStorage.getItem('auctionItemList'))

      //   return this.auctionItemList.length < 6 ? this.auctionItemList.length : 6
      // },
      webSocketOnmessage(e) {
        // 数据接收
        const reData = utils.baseDecode(e.data)
        if (reData.data === '' || reData.data.return_type === 'login') {
          this.timeoutObj = setTimeout(() => {
            this.webSock.send(utils.baseEncode('heartbeat'))
          }, 20000)
        }

        if (reData.code === 0 && reData.data !== '') {
          if (reData.data.earnest_money !== undefined) {
            localStorage.setItem('earnestMoney', reData.data.earnest_money)
          }
          if (reData.data.is_auction_list === true) {
            this.getLiveAuctionItemList()
          }
          if (reData.data.next_item !== undefined) {
            //console.log(reData.data.next_item);
            this.nextItem = reData.data.next_item
          }
          this.earnestMoney = localStorage.getItem('earnestMoney')

          if (reData.data.exchange_rate !== undefined) {
            localStorage.setItem('exchangeRate', reData.data.exchange_rate)
          }
          if (reData.data.user_available_balance !== undefined) {
            this.userAvailableBalance = reData.data.user_available_balance
            localStorage.setItem(
              'userAvailableBalance',
              reData.data.user_available_balance
            )
          } else {
            this.userAvailableBalance = localStorage.getItem(
              'userAvailableBalance'
            )
          }
          // if (reData.data.return_type === 'offer') {
          //   this.getAccountDetailsList()
          // }
          this.exchangeRate = localStorage.getItem('exchangeRate')
          if (reData.data.message_list !== undefined) {
            localStorage.setItem(
              'messageList',
              JSON.stringify(reData.data.message_list)
            )
          }
          this.messageList = JSON.parse(localStorage.getItem('messageList'))
          this.userAvailableBalance = localStorage.getItem('userAvailableBalance')
          // this.userAvailableBalance = reData.data.user_available_balance

          // if (reData.data.auction_item_list !== undefined) {
          //   console.log('开始存socket的数据')
          //   console.log(JSON.stringify(reData.data.auction_item_list))
          //   localStorage.setItem(
          //     'auctionItemList',
          //     JSON.stringify(reData.data.auction_item_list)
          //   )
          // }
          if (reData.data.auction_status === 3) {
            this.btnPriceShow = true
          } else {
            this.btnPriceShow = false
          }
          // this.auctionItemListFor(JSON.parse(localStorage.getItem('auctionItemList')))
          this.auctionItemList = JSON.parse(
            localStorage.getItem('auctionItemList')
          )

          // this.value1 = Number(localStorage.getItem('countLength'))
          this.value2 = Number(localStorage.getItem('countLength'))
          if (reData.data.offer_data !== undefined) {
            localStorage.setItem(
              'offerData',
              JSON.stringify(reData.data.offer_data)
            )
          }
          if (reData.data.offer_data_258 !== undefined) {
            localStorage.setItem(
              'offerData258',
              JSON.stringify(reData.data.offer_data_258)
            )
          }

          if (reData.data.initial_price !== undefined) {
            if (reData.data.initial_price === 0) {
              this.initialPrice = reData.data.current_price
            } else {
              this.initialPrice = reData.data.initial_price
              this.money = reData.data.initial_price
            }
          }

          if (reData.data.currency !== undefined) {
            localStorage.setItem('currency', reData.data.currency)
          }
          this.currency = localStorage.getItem('currency')
          if (reData.data.user_available_balance !== undefined) {
            if (reData.data.user_available_balance === 0) {
              if (localStorage.getItem('accessToken')) {
                // this.getAccountDetailsList()
              }
            } else {
              this.userAvailableBalance = reData.data.user_available_balance
            }
          } else {
            if (localStorage.getItem('accessToken')) {
              // this.getAccountDetailsList()
            }
          }
          if (reData.data.bidding === 'A' && reData.data.initial_price === 0) {
            this.nextPrice(JSON.parse(localStorage.getItem('offerData')))
          } else if (
            reData.data.bidding === 'B' &&
            reData.data.initial_price === 0
          ) {
            this.offerData258()
          } else if (
            reData.data.bidding === 'C' &&
            reData.data.initial_price === 0
          ) {
            localStorage.setItem('equidistant', reData.data.equidistant)
            this.money =
              Number(this.initialPrice) +
              Number(localStorage.getItem('equidistant'))
          }
          if (reData.data.is_show_live === 1) {
            this.audienceJoinShowBtn = false
          }

          this.exchangeRateCount()

          // this.startK()
          // console.log('执行')
          // this.getcurrentPage()
          // this.getResultsData()

          // const index = this.newArry.findIndex(item => item.id === this.nextItem)
          // if (index === -1) {
          //   this.rightScroll()
          // }
        } else {
          if (reData.data !== '') {
            this.$message.error(reData.msg)
          }
          if (reData.code === -1) {
            this.$message.error(reData.msg)
          }
        }
      },
      webSocketSend(Data) {
        // 数据发送
        this.webSock.send(Data)
      },
      webSocketClose(e) {
        // 关闭
        // this.reconnect() // 重连
      },
      reconnect() {
        // 重新连接
        var that = this
        if (that.lockReconnect) {
          return
        }
        that.lockReconnect = true
        // 没连接上会一直重连，设置延迟避免请求过多
        that.timeoutnum && clearTimeout(that.timeoutnum)
        that.timeoutnum = setTimeout(function() {
          that.initWebSocket() // 新连接

          that.lockReconnect = false
        }, 5000)
      },
      reset() {
        // 重置心跳
        var that = this
        // 清除时间
        clearTimeout(that.timeoutObj)
        clearTimeout(that.serverTimeoutObj)
        // 重启心跳
        that.startK()
      },
      startK() {
        // 开启心跳
        var self = this
        self.timeoutObj && clearTimeout(self.timeoutObj)
        self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj)
        self.timeoutObj = setTimeout(function() {
          // 这里发送一个心跳，后端收到后，返回一个心跳消息，
          // if (self.webSock.readyState === 1) { // 如果连接正常
          // } else { // 否则重连
          //   self.reconnect()
          // }
          self.webSock.send('heartbeat')

          // self.serverTimeoutObj = setTimeout(function () {
          //   // 超时关闭
          //   self.webSock.close()
          // }, self.timeout)
        }, self.timeout)
      },
      // 汇率计算
      exchangeRateCount() {
        this.exchangeCount = utils.toDecimal2(
          Number(this.money) * Number(this.exchangeRate)
        )
      },
      loginOpen() {
        this.$nextTick(() => {
          this.$refs.login.open()
        })
      },
      // 立即出价
      makeImmediateOffer() {
        if (!localStorage.getItem('accessToken')) {
          this.$message.error('请您先登录')
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        if (this.cardStatus !== 2) {
          this.$message.error('请您先在个人中心进行实名认证')
          return
        }
        // const userMoney = this.userAvailableBalance.replace(/,/g, '')
        // if (Number(userMoney) < Number(this.money)) {
        //   this.$message.error('您的余额不足')
        //   return
        // }
        this.data = utils.baseEncode(
          'offer' +
          ',' +
          this.$route.query.liveId +
          ',' +
          localStorage.getItem('accessId') +
          ',' +
          this.$route.query.auctionId +
          ',' +
          this.money
        )
        this.webSock.send(this.data)
        // this.getAccountDetailsList()
        // this.nextPrice(JSON.parse(localStorage.getItem('offerData')))
      },
      // 立即充值
      payGo() {
        this.$router.push({
          path: '/personalcenter',
          name: 'Personalcenter'
        })
      },
      // 取得下口价格
      nextPrice(item) {
        for (let index = 0; index < item.length; index++) {
          if (
            Number(item[index]) > Number(this.initialPrice) &&
            Number(item[index]) !== Number(this.initialPrice)
          ) {
            this.money = Number(item[index])
            this.exchangeRateCount()
            return
          }
        }
      },
      // 切换258
      offerData258() {
        const params = JSON.parse(localStorage.getItem('offerData258'))
        for (let index = 0; index < params.length; index++) {
          if (Number(params[index]) > Number(this.initialPrice)) {
            this.money = Number(params[index])
            this.exchangeRateCount()

            return
          }
        }
      },
      // 只拿一个拍品
      auctionItemListFor(item) {
        this.auctionItemOne = item.find(item => item.id == this.nextItem)
        // for (let index = 0; index < item.length; index++) {
        //   if (item[index].status === '待拍') {
        //     this.auctionItemOne = item[index]
        //     return
        //   }
        // }
      },
      // async getAccountDetailsList() {
      //   const res = await getAccountDetails(this.getAccountDetailsData)
      //   if (res.code === 0) {
      //     this.userAvailableBalance = res.data.availableAvailableBalance
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // },
      // 返回当前拍品
      goBack() {
        this.currentPage = 1
        this.getResultsData()
      },
      async userInfo() {
        const res = await userInfo({
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.cardStatus = res.data.status
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-buyer-live {
    p {
      margin-bottom: 0;
    }

    .live-auction-house-header {
      background: #fff;
      border: 1px solid #dedede;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 100px;

      .live-auction-house-header-1 {
        text-align: left;
        flex: 1;

        img {
          width: 280px;
        }
      }

      .live-auction-house-header-2 {
        flex: 1;

        .title-1 {
          font-size: 24px;
          font-weight: 500;
          color: #333;
          margin-bottom: 5px;
        }

        .title-2 {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }

      .live-auction-house-header-3 {
        text-align: right;
        flex: 1;

        .welcome-1 {
          margin-bottom: 5px;
          font-size: 24px;
          font-weight: 500;
          color: #333;
        }

        .welcome-2 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;

          a {
            color: #cda156;
          }

          .line-pad {
            padding: 0 10px;
            color: #cda156;
          }
        }
      }
    }

    .buyer-live-middle {
      background: #f9f9f9;
      padding: 30px 90px 58px 90px;
      display: flex;

      .buyer-live-middle-content-1 {
        .buyer-live-middle-content-1-top {
          background: #fff;
          border-radius: 20px;
          padding: 30px;
          display: flex;

          .buyer-live-middle-content-1-top-left {
            width: 520px;
            height: 520px;
            border: 1px solid #dedede;

            .col-md-3 {
              margin: 0;
              border: none;
              height: none;
              display: flex;
              // justify-content: center;
              // align-items: center;
              // width: 540px;
              height: 100%;

              .thumb-container {
                width: 100%;
                // height: 405px;
                position: relative;
                // padding-bottom: 100%;
                margin: 0;
                border: none;

                &:hover {
                  box-shadow: none;
                }

                .thumb {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  margin: 0;
                  border-top: none;
                  border-bottom: none;
                  border-left: none;

                  // top: 32%;
                  &:hover {
                    box-shadow: none;
                  }

                  a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;

                    img {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                      max-height: 100%;
                    }
                  }
                }
              }
            }
          }

          .buyer-live-middle-content-1-top-right {
            text-align: left;
            margin-left: 30px;

            .p-1 {
              width: 500px;
              font-size: 32px;
              font-weight: 600;
              color: #333;
              margin-bottom: 30px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .p-2 {
              font-size: 24px;
              font-weight: 600;
              color: #333;
              margin-bottom: 10px;
            }

            .p-3 {
              font-size: 20px;
              color: #333333;
              font-weight: 400;
              margin-bottom: 30px;
            }

            .p-4 {
              font-size: 24px;
              font-weight: 600;
              color: #333;
              margin-bottom: 15px;
            }

            .p-5::-webkit-scrollbar {
              display: none;
            }

            .p-5 {
              font-size: 20px;
              color: #333333;
              font-weight: 400;
              width: 500px;
              height: 200px;
              overflow-y: scroll;
            }
          }
        }

        .buyer-live-middle-content-1-right {
          margin-top: 10px;
          background: #fff;
          border-radius: 20px;
          padding: 20px 40px;
          width: 1160px;

          .buyer-live-middle-content-1-right-top {
            display: flex;
            align-items: center;

            .text-font {
              font-size: 25px;
              font-weight: 600;
            }

            .active {
              border: 1px solid #cda156;
            }

            .img-w-bor {
              border: 1px solid rgb(209, 0, 0);
            }

            .img-w {
              width: 150px;
              height: 220px;

              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
              margin: 0 10px;
              position: relative;

              .h-block {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0);
                // line-height: 150px;
                padding-top: 47px;
                opacity: 0.3;
                height: 150px;
              }

              .img-w-content {
                margin-top: 10px;

                p {
                  font-size: 16px;
                  font-weight: 400;
                  color: #333;
                }

                p:last-child {
                  margin-top: 6px;
                }
              }

              .col-md-3 {
                margin: 0;
                border: none;
                height: none;
                display: flex;
                // justify-content: center;
                // align-items: center;
                // width: 150px;
                height: 150px;
                border-bottom: 1px solid #f3f3f3;

                .thumb-container {
                  width: 100%;
                  // height: 405px;
                  position: relative;
                  // padding-bottom: 100%;
                  margin: 0;
                  border: none;

                  &:hover {
                    box-shadow: none;
                  }

                  .thumb {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    margin: 0;
                    border-top: none;
                    border-bottom: none;
                    border-left: none;

                    // top: 32%;
                    &:hover {
                      box-shadow: none;
                    }

                    a {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                      max-height: 100%;

                      img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        max-width: 100%;
                        max-height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }

          .buyer-live-middle-content-1-right-bottom {
            margin-top: 24px;
            display: flex;
            align-items: center;

            a {
              text-decoration: underline;
              color: #cda156;
              font-size: 16px;
              font-weight: 600;
            }

            .silder-right {
              width: 800px;
              margin: 0 10px;
            }

            .first-c {
              margin-left: 50px;
              margin-right: 10px;
            }

            p {
              font-size: 20px;
              font-weight: 400;
              color: #333;
            }

            .first-c-1 {
              margin-left: 10px;
            }
          }
        }
      }

      .buyer-live-middle-content-2 {
        margin-left: 20px;
        width: 560px;

        .buyer-live-middle-content-2-top {
          background: #fff;
          border-radius: 20px;
          padding: 20px;
          height: 710px;

          .look-video {
            display: block;
            width: 120px;
            height: 40px;
            background: #cda156;
            border-radius: 4px;
            line-height: 40px;
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: 40px;
            // float: right;
            margin-left: 400px;
          }

          .buyer-live-middle-content-2-top-video {
            width: 520px;
            height: 300px;
            background: #d8d8d8;
            border: 1px solid #979797;
            margin-bottom: 20px;
            position: relative;

            i {
              position: absolute;
              z-index: 1000;
              right: 20px;
              top: 20px;
            }
          }

          .message-content-more {
            .message-content-more-scroll {
              width: 520px;
              height: 50px;
              text-align: center;
              line-height: 50px;
              font-size: 18px;
              color: #6e5121;
              border-radius: 25px;
              background: #e1c79a;
              margin-bottom: 10px;
            }

            .message-content-more-scroll-toggle {
              background: #fafafa;
              color: #d10000;
            }

            .message-content-more-scroll-toggle-1 {
              background: #fafafa;
              color: #333;
            }

            .message-content-more-scroll-toggle-2 {
              background: #fafafa;
              color: #26a920;
            }

            .message-content-more-scroll:last-child {
              margin-bottom: 0;
            }
          }
        }

        .buyer-live-middle-content-2-bottom {
          background: #fff;
          border-radius: 20px;
          padding: 20px;
          margin-top: 10px;

          .price-s-b {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 400;
            color: #333;

            .price-s-b-right {
              a {
                text-decoration: underline;
                color: #cda156;
                font-size: 16px;
                padding-left: 10px;
              }
            }
          }

          .price-s-b-btn {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            margin-bottom: 12px;

            a {
              display: block;
              width: 520px;
              height: 60px;
              background: #cda156;
              border-radius: 9px;
              color: #fff;
              font-size: 20px;
              line-height: 60px;
              text-align: center;

              span {
                color: #fff;
                font-weight: 600;
              }
            }

            .false-price {
              background: #979797;

              span {
                color: #fff;
                font-weight: 600;
              }
            }

            span {
              padding-top: 5px;
              font-size: 18px;
              color: #333;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
</style>
