import AgoraRTC from 'agora-rtc-sdk-ng'

const rtc = {
  // For the local audio and video tracks.
  localAudioTrack: null,
  localVideoTrack: null,
  client: null
}

// const options = {
//   // Pass your app ID here.
//   appId: 'bac2562465b34ba0b4865724cd957bac',
//   // Set the channel name.
//   channel: '7608',
//   // Use a temp token
//   token: '006bac2562465b34ba0b4865724cd957bacIAB8/PTkwNCaENBHjKMRYdwovx4Fyzq9t5m0CHTl/gxon0sIGpsAAAAAIgDHSsgDi8HnYQQAAQDLGOdhAgDLGOdhAwDLGOdhBADLGOdh',
//   // Uid
//   uid: 0
// }

async function startBasicLiveStreaming (appId, channel, token, uid) {
  rtc.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' })

  rtc.client.setClientRole('audience')
  await rtc.client.join(appId, channel, token, uid)

  rtc.client.on('user-published', async (user, mediaType) => {
    // Subscribe to a remote user.
    await rtc.client.subscribe(user, mediaType)

    // If the subscribed track is video.
    if (mediaType === 'video') {
      // Get `RemoteVideoTrack` in the `user` object.
      const remoteVideoTrack = user.videoTrack
      // Dynamically create a container in the form of a DIV element for playing the remote video track.
      const remotePlayerContainer = document.createElement('div')
      const videoDiv = document.getElementById('video')
      // Specify the ID of the DIV container. You can use the `uid` of the remote user.
      remotePlayerContainer.id = user.uid.toString()
      // remotePlayerContainer.textContent = 'Remote user ' + user.uid.toString()
      remotePlayerContainer.style.width = '2.708333rem'
      remotePlayerContainer.style.height = '1.5625rem'
      // document.body.append(remotePlayerContainer)
      videoDiv.append(remotePlayerContainer)
      // Play the remote video track.
      // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
      remoteVideoTrack.play(remotePlayerContainer)

      // Or just pass the ID of the DIV container.
      // remoteVideoTrack.play(playerContainer.id);
    }
    // If the subscribed track is audio.
    if (mediaType === 'audio') {
      // Get `RemoteAudioTrack` in the `user` object.
      const remoteAudioTrack = user.audioTrack
      // Play the audio track. No need to pass any DOM element.
      remoteAudioTrack.play()
    }
  })
  rtc.client.on('user-unpublished', user => {
    // Get the dynamically created DIV container.
    const remotePlayerContainer = document.getElementById(user.uid)
    // Destroy the container.
    remotePlayerContainer.remove()
  })

  document.getElementById('leave').onclick = async function () {
    // Close all the local tracks.
    // rtc.localAudioTrack.close()
    // rtc.localVideoTrack.close()
    // // Traverse all remote users.
    // rtc.client.remoteUsers.forEach(user => {
    //   // Destroy the dynamically created DIV containers.
    //   const playerContainer = document.getElementById(user.uid)
    //   playerContainer && playerContainer.remove()
    // })

    // Leave the channel.
    await rtc.client.leave()
  }
}
export default startBasicLiveStreaming
